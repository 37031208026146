<template>
  <g
    ref="chart_ref"
    :class="[classObject]"
    :data-regional="item.regional"
    @click="clickAction"
  >
    <path :d="item.svgData" />
    <text :transform="item.textData" style="font-size: 14px" fill="white">
      {{ item.pessoas || 0 }}
    </text>
    <circle
      v-if="showCircleInfo"
      :cy="item.circleData.cy"
      :cx="item.circleData.cx"
      r="10"
      :class="[counterClassObject]"
    />
  </g>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        altText: "--",
      }),
    },
    showCircleInfo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: false,
      counterActive: false,
      altText: this.item.altText || "-",
      chart_ref: null,
    };
  },
  computed: {
    classObject() {
      return this.active ? ["mapa-svg-estados-active"] : ["mapa-svg-estados"];
    },
    counterClassObject() {
      return this.counterActive ? ["sphere active"] : ["sphere"];
    },
  },
  methods: {
    clickAction() {
      console.log("mapa-estado: clickAction");
      this.$emit("stateSelectedEvent", {
        src: {
          item: this.item,
        },
        enable: this.enable,
      });
    },
    resetAction() {
      this.disable();
    },
    disable() {
      this.active = false;
      this.hideCounter();
    },
    enable() {
      this.active = true;
      this.showCounter();
    },
    setAltText(val) {
      this.altText = val;
    },
    hideCounter() {
      this.counterActive = false;
    },
    showCounter() {
      this.counterActive = true;
    },
  },
  mounted() {
    this.altText = this.item.altText;
  },
};
</script>

<style>
</style>
