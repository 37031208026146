const base64ToPNG = (base64String, fileName) => {
  // Remova a parte inicial "data:image/jpeg;base64,"
  const base64WithoutHeader = base64String.replace(
    /^data:image\/pgn;base64,/,
    ""
  );

  // Converte a string base64 para um array de bytes
  const byteCharacters = atob(base64WithoutHeader);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Cria um objeto Blob contendo os dados da imagem
  const blob = new Blob([byteArray], { type: "image/png" });

  // Cria um link para download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName || "image.png";

  // Adiciona o link ao documento, clica nele e remove-o
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export { base64ToPNG };
