<template>
  <v-card style="height: 100%" v-if="mounted">
    <v-card-title class="accent" style="height: 70px;">
      <div class="d-flex justify-center">
        <v-icon>mdi-map-marker</v-icon>
        <span class=" ml-2">
          {{ title }}
        </span>
      </div>
      <v-spacer></v-spacer>
        <v-icon class="px-1" color="primary" @click="downloadImage">mdi-tray-arrow-down</v-icon>
        <v-icon @click="reloadData" color="primary">mdi-reload</v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <div style="display: flex; flex-direction: column">
        <svg
          ref="ref_mapa"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="120 50 500 500"
          @click="mapclick"
        >
          <g id="mapa-svg-area">
            <MapaEstado
              v-for="item in items"
              ref="child"
              :key="item.uf"
              class="mapa-svg-estados"
              :item="item"
              :showCircleInfo="showCircleInfo"
              :class="item.classObject"
              :style="item.color ? `fill: ${item.color}` : ''"
              @stateSelectedEvent="onStateSelectedEvent"
              @onStateMouseOverEvent="onStateMouseOverEvent"
            />
          </g>
        </svg>
        <div class="pa-4" style="border-radius: 0.5rem">
          <div
            v-if="selected"
            style="display: flex; flex-direction: column; gap: 0.5rem"
          >
            <span>
              <b> Estado:</b> {{ dadosEstado.name }} -
              {{ dadosEstado.uf.toUpperCase() }}
            </span>
            <span><b>Participantes:</b> {{ dadosEstado.pessoas || 0 }}</span>
          </div>
          <div
            v-else
            style="display: flex; flex-direction: column; gap: 0.5rem"
          >
            <span>
              <b>Selecione um estado</b>
            </span>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import MapaEstado from "../MapaEstado.vue";
import DadosBrasil from "./DadosBrasil.js";
import { base64ToPNG } from "../../../../../helpers/base64ToPNG.js";
import api from "@/api/axios_service";

export default {
  components: {
    MapaEstado,
  },
  props: {
    title: {
      type: String,
      default: () => "Participantes por estado",
    },
    subtitle: {
      type: String,
      default: () => "Total de participantes por estado",
    },
  },
  data() {
    return {
      selected: null,
      showCircleInfo: false,
      infoBoxActive: false,
      infoBoxPosX: 0,
      infoBoxPosY: 0,
      infoBoxData: "",
      child: [],
      items: DadosBrasil,
      ref_mapa: null,
      data: [],
      mounted: false,
    };
  },
  computed: {
    dadosEstado() {
      const estado = this.items.find((item) => item.uf === this.selected);
      return estado;
    },
  },
  methods: {
    mapclick(event) {
      console.log("mapa-brasil: mapclick");
      if (event.target.tagName == "svg") {
        this.resetSelectionAction();
        this.infoBoxActive = false;
      }
    },
    onStateSelectedEvent(args) {
      console.log("mapa-brasil: onStateSelectedEvent: ", args);
      this.selected = args.src.item.uf;
      this.resetSelectionAction();
      args.enable();
    },
    onStateMouseOverEvent(args) {
      console.log("mapa-brasil: onStateMouseOverEvent: ", args);
      this.setInfoBoxPosition({ x: args.event.pageX, y: args.event.pageY });
      this.setInfoBoxData([
        args.src.item.name,
        args.src.item.regional,
        args.src.item.altText,
      ]);
      this.infoBoxActive = true;
    },
    resetSelectionAction() {
      this.$refs.child.forEach((childComponent) => {
        childComponent.resetAction();
      });
    },
    setInfoBoxPosition(args) {
      this.infoBoxPosX = args.x + 30;
      this.infoBoxPosY = args.y;
    },
    setInfoBoxData(args) {
      this.infoBoxData = args.filter(function (a) {
        return a !== "" ? a : null;
      });
    },
    downloadImage() {
      const svgElement = this.$refs.ref_mapa;
      const svgString = new XMLSerializer().serializeToString(svgElement);
      const base64String = btoa(svgString);
      base64ToPNG(base64String, this.title);
    },
    async reloadData() {
      const { data } = await api.get(`/graficos/grafico-estados-paises`);
      this.data = data.result;
      const dataOrganizada = [...this.data].sort(
        (a, b) => a?.total_pessoas_por_estado - b?.total_pessoas_por_estado
      );
      const maiorNumero =
        dataOrganizada[dataOrganizada.length - 1]?.total_pessoas_por_estado;
      const faixa = maiorNumero / 5;
      const colors = ["#ffd54f", "#ffca28", "#ffc107", "#ffb300", "#ffa000"];
      this.items.forEach((item) => {
        const estado = dataOrganizada.find((e) => e.estado === item.uf);
        if (estado) {
          let indexFaixa = Math.ceil(estado.total_pessoas_por_estado / faixa);
          indexFaixa = Math.min(indexFaixa, colors.length) - 1;
          item.color = colors[indexFaixa];
          item.pessoas = estado.total_pessoas_por_estado;
        } else {
          item.color = "#424242";
          item.pessoas = 0;
        }
      });
    },
  },
  async mounted() {
    const { data } = await api.get(`/graficos/grafico-estados-paises`);
    this.data = data.result;
    const dataOrganizada = [...this.data].sort(
      (a, b) => a?.total_pessoas_por_estado - b?.total_pessoas_por_estado
    );
    const maiorNumero =
      dataOrganizada[dataOrganizada.length - 1]?.total_pessoas_por_estado;
    const faixa = maiorNumero / 5;
    const colors = ["#ffd54f", "#ffca28", "#ffc107", "#ffb300", "#ffa000"];
    this.items.forEach((item) => {
      const estado = dataOrganizada.find((e) => e.estado === item.uf);
      if (estado) {
        let indexFaixa = Math.ceil(estado.total_pessoas_por_estado / faixa);
        indexFaixa = Math.min(indexFaixa, colors.length) - 1;
        item.color = colors[indexFaixa];
        item.pessoas = estado.total_pessoas_por_estado;
      } else {
        item.color = "#424242";
        item.pessoas = 0;
      }
    });
    this.mounted = true;
  },
};
</script>

<style lang="scss">
$default-stroke: #424242;

svg text {
  font-family: monospace;
  fill: $default-stroke;
}

.mapa-svg-estados {
  -webkit-transition: 0.8s ease;
  -moz-transition: 0.8s ease;
  -ms-transition: 0.8s ease;
  -o-transition: 0.8s ease;
  transition: 0.8s ease;
  stroke-dasharray: 180%;
  stroke-dashoffset: -120%;
  stroke-width: 1px;
  fill: $default-stroke;
  text {
    fill: #ffffff;
    stroke: none !important;
  }
}

.mapa-svg-estados:hover {
  cursor: pointer;
  fill: #ffe2ab !important;
}

.mapa-svg-estados-active {
  cursor: pointer;
  stroke: #ffffff;
  fill: #ffc712 !important;
  stroke-dashoffset: 0%;
  transition: 0.8s ease;
  -webkit-transition: 0.8s ease;
  -moz-transition: 0.8s ease;
  -ms-transition: 0.8s ease;
  -o-transition: 0.8s ease;
  text {
    fill: #ffffff;
    stroke: none !important;
  }
}
</style>
